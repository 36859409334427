<template>
    <div class="text-xs">
      <br>
      <br>
      <br>
        <div class="">
            <div class="row justify-content-center">
              <div class="col-md-10" v-if="vdata2.modal_akhir">
                <span class="float-right">{{vdata.tanggal2}}</span>
                <span>{{vdata.tanggal1}}</span>
                <p class="text-lg">Report untung Penjualan</p>
                   <hr>
                    <table class="table table-sm table-responsive">
                        <tr>
                            <td>tanggal</td>
                            <td>nama produk</td>
                            <td>Harga</td>
                            <td>Jumlah</td>
                            <td>Satuan</td>
                            <td>Total Jual/diskon</td>
                            <td>Total Beli (jumlah penjualan)</td>
                            <td>Untung/Rugi</td>
                        </tr>
                        <tr v-for="(item, index) in transaksi" :key="index+'transaksi'">
                            <td>{{format_tanggal(item.tanggal)}}</td>
                            <td>{{item.nama_produk}}</td>
                            <td>{{formatRupiah(item.harga,'Rp. ')}}</td>
                            <td>{{item.jumlah_jual}}</td>
                            <td>{{item.satuan}}</td>
                            <td>{{formatRupiah(untung[index].jumlah_jual,'Rp. ')}} / {{item.diskon}}%</td>
                            <td>{{formatRupiah(untung[index].jumlah_beli,'Rp. ')}}</td>
                            <td>{{item.type!='3'?formatRupiah(untung[index].jumlah_jual-untung[index].jumlah_beli,'Rp. '):formatRupiah(untung[index].jumlah_jual,'Rp. ')}}</td>
                        </tr>
                        <tr>
                            <td class="font-bold">TOTAL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="font-bold">{{formatRupiah(jumlah_jual-jumlah_beli,'Rp. ')}}</td>
                        </tr>
                    </table>
                    <hr class="style16">
                    <br>
                  <p class="text-lg">Report untung Modal_akhir + Penjualan</p>
                  <table class="table table-sm table-responsive">
                    <tr>
                      <td>Modal awal</td>
                      <td>Penambahan Modal</td>
                      <td>Pengurangan Modal</td>
                      <td>Total Modal</td>
                      <td>Barang Masuk</td>
                      <!-- <td>Barang Keluar</td> -->
                      <td>Penjualan</td>
                    </tr>
                    <tr>
                      <td>{{formatRupiah(vdata2.modal,'Rp. ')}}</td>
                      <td>{{formatRupiah(vdata2.modal_penambahan,'Rp. ')}}</td>
                      <td>{{formatRupiah(vdata2.modal_pengeluaran,'Rp. ')}}</td>
                      <td>{{formatRupiah(vdata2.modal_akhir,'Rp. ')}}</td>
                      <td>{{formatRupiah(masuks ,'Rp. ')}}</td>
                      <!-- <td>{{formatRupiah(keluars,'Rp. ')}}</td> -->
                      <td>{{formatRupiah(jumlah_jual-jumlah_beli,'Rp. ')}}</td>
                    </tr>
                    <tr>
                      <td class="font-bold">TOTAL</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td></td> -->
                      <td class="font-bold">Rp. {{(parseInt((vdata2.modal_akhir-parseInt(masuks)))+(jumlah_jual-jumlah_beli)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")}}</td>
                    </tr>
                  </table>
                  <div style="height:300px;">
                    
                  </div>
              </div>
              <div class="col-md-10" v-else>
                <form  @submit.prevent="submit">
                  <div class="row justify-content-center">
                    <div class="offset-1 col-5 shadow p-3">
                        <p class="text-center p-3">Cari data Stok Barang Keluar dari S/D tanggal</p>
                        <form action="" @submit.prevent="submit">
                            <div class="sm-form ">
                                <input type="date" id="tanggal1" name="tanggal1" class="form-control form-control-sm" placeholder="tanggal1" v-model="vdata.tanggal1" >
                            </div>
                            <div class="sm-form ">
                                <input type="date" id="tanggal2" name="tanggal2" class="form-control form-control-sm" placeholder="tanggal2" v-model="vdata.tanggal2" >
                            </div>
                            <hr>
                            <button type="submit" class="btn btn-sm btn-dark  ">Cari</button>
                        </form>
                      <!-- END BUTTON PART -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
       
        </div>
</template>
<script>
import axios from 'axios'
import autophp from '@/plugins/autophp_kasir'
let sdb = new autophp();
export default{
    data(){
        return{
            number:6,
            total:0,
            uang:0,
            transaksi:[],
            datanya:[],
            untung:[],
            jumlah_beli:0,
            jumlah_jual:0,
            vdata2:{},
            masuks:0,
            keluars:0,
            vdata:{}
        }
    },
    methods: {
        prints(){
            window.print();
        },
        formatRupiah(angka, prefix){
          angka=angka.toString();
          var number_string = angka.replace(/[^,\d]/g, '').toString(),
          split   		= number_string.split(','),
          sisa     		= split[0].length % 3,
          rupiah     		= split[0].substr(0, sisa),
          ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
    
          // tambahkan titik jika yang di input sudah menjadi angka ribuan
          if(ribuan){
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
          }
    
          rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
          return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
  		},
         format_tanggal(tanggal) {
            console.log(tanggal)
              return this.$datefns.format(
                new Date(tanggal),
                "DD MMMM YYYY-HH-mm"
              );
            },
        submit(){
          this.getData()
          this.getData2()
        },
        async getData2(){
          let penambahan = await sdb.collection("app_kasir_toko_penambahan",false).doc().select(`select * from app_kasir_toko_penambahan tb1 WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}'`)
          let pengeluaran = await sdb.collection("app_kasir_toko_pengeluaran",false).doc().select(`select * from app_kasir_toko_pengeluaran tb1 WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}'`)
          
          console.log('penambahan',penambahan)
          console.log('pengeluaran',pengeluaran)
          if(penambahan.length>0){
            penambahan=penambahan.reduce((e,n)=>{
              return e+parseInt(n.jumlah)
            },0)
          }else{
            penambahan=0;
          }
          if(pengeluaran.length>0){
            pengeluaran=pengeluaran.reduce((e,n)=>{
              return e+parseInt(n.jumlah)
            },0)
          }else{
            pengeluaran=0;
          }
            console.log('penambahan',penambahan)
          console.log('pengeluaran',pengeluaran)
          sdb.collection("app_kasir_toko_modal",false).doc().select(`select * from app_kasir_toko_modal `).then(res => {
              // this.datanya=res;
              this.vdata2=res[0]
              this.vdata2.modal_penambahan=penambahan
              this.vdata2.modal_pengeluaran=pengeluaran
              this.vdata2.modal_akhir=(parseInt(res[0].modal)+penambahan)-pengeluaran
              this.$forceUpdate();
            });
        },
        getData(){
          sdb.collection("app_kasir_transaksi_nota",false).doc().select(`
          SELECT tb1.tanggal,tb3.nama_produk,tb2.harga,tb2.total,tb2.diskon,tb2.jumlah as jumlah_jual,tb2.diskon,tb3.harga_beli,tb4.satuan,tb4.perkalian,tb3.type
           FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota left join app_kasir_produk tb3 on tb3.id=tb2.id_produk left join app_kasir_produk_satuan tb4 on tb2.satuan=tb4.id
            WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}' AND tb1.status='3'
            order by tb1.id`).then(res => {
                this.transaksi=res;
                this.untung = res.map(e=>{
                     let diskon = ((parseInt(e.diskon)/100)*parseInt(e.harga))*parseInt(e.jumlah_jual)
                    return {
                        jumlah_beli:e.type!='3'?parseInt(e.harga_beli)*parseInt(e.jumlah_jual)*parseInt(e.perkalian):0,
                        jumlah_jual:parseInt(e.total),
                    }
                })
                this.jumlah_beli=this.untung.reduce((e,n)=>{
                    return e+n.jumlah_beli
                },0)
                this.jumlah_jual=this.untung.reduce((e,n)=>{
                    return e+n.jumlah_jual
                },0)
                console.log('untung',this.untung)
                console.log('jumlah_jual',this.jumlah_jual)
                console.log('jumlah_beli',this.jumlah_beli)
                console.log(this.transaksi)
            });
            // sdb.collection("app_kasir_transaksi_nota").doc().get("*",`where id='${this.$route.query.id}' order by id`).then(res => {
            //     this.datanya=res;
            // });
        sdb.collection("app_kasir_stok_masuk",false).doc().select(`select tb1.id,tb2.nama_produk,tb2.barcode,tb1.harga,tb1.tanggal,tb1.jumlah,tb3.nama,tb1.status,tb1.keterangan from app_kasir_stok_masuk tb1 
          left join app_kasir_produk tb2 on tb2.id=tb1.id_produk left join app_kasir_supplier tb3 on tb3.id=tb1.id_supplier     
          WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}'`).then(res => {
          this.masuks=res.reduce((e,n)=>{
            return e+(parseInt(n.harga)*parseInt(n.jumlah))
          },0);
          this.$forceUpdate();
        });
        sdb.collection("app_kasir_stok_keluar",false).doc().select(`select tb1.id,tb2.nama_produk,tb2.barcode,tb1.harga,tb1.tanggal,tb1.jumlah,tb1.status,tb1.keterangan from app_kasir_stok_keluar tb1 
          left join app_kasir_produk tb2 on tb2.id=tb1.id_produk 
          WHERE tb1.tanggal_w >= '${this.vdata.tanggal1}' AND tb1.tanggal_w <= '${this.vdata.tanggal2}'`).then(res => {
           this.keluars=res.reduce((e,n)=>{
            return e+(parseInt(n.harga)*parseInt(n.jumlah))
          },0);
          this.$forceUpdate();
        });
        }
    },
    mounted() {
       
    },
}
</script>
<style scoped>
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  .table-print {
    border: 1px solid black;
  }

  .d-footer-printer-ttd{
    display:block;
    position:absolute;
    bottom:150px;
    right:100px;
  }
}
</style>